import React, { Component } from "react";
export default class Footer extends Component {
  render() {
    // let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
        {/*generated code*/}
        <footer>
          <div className="row">
            {/* <div className="twelve columns">
              <ul className="social-links">
                <li>
                  <a href={resumeData.linkedin} target="_blank">
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href={resumeData.instagram} target="_blank">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href={resumeData.github} target="_blank">
                    <i className="fa fa-github" />
                  </a>
                </li>
              </ul>
            </div> */}
            {/* <div className="twelve columns">Made with React</div> */}
            <div id="go-top">
              <a className="smoothscroll" title="Back to Top" href="#home">
                <i className="icon-up-open" />
              </a>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

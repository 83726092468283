let resumeData = {
  imagebaseurl: "davemeneses.com",
  name: "Dave Meneses",
  role: "Developer",
  github: "https://github.com/davemeneses",
  instagram: "https://www.instagram.com/davedrinks/",
  linkedin: "https://www.linkedin.com/in/davemeneses/",
  twitter: "https://twitter.com/DaveMeneses"
};
export default resumeData;

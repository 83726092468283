import React, { Component } from "react";
export default class About extends Component {
  render() {
    const divStyle = {
      display: "none"
    };
    return (
      <div style={divStyle}>
        <img alt="Secret Shirt" src="images/shirts/shirt.png" />
      </div>
    );
  }
}

import React, { Component } from "react";
export default class About extends Component {
  render() {
    return (
      <React.Fragment>
        {/*generated code*/}
        <section id="about">
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src="images/headshot.jpg"
                alt="Dave Meneses"
              />
            </div>
            <div className="nine columns main-col">
              <h2>About Me</h2>
              <p>
                Ex-beverage professional turned full-stack developer with a
                focus on object-oriented programming and the user experience. An
                experienced manager and educator who values communication and is
                able to be both a leader and a productive team player. A
                creative problem-solver with the ability to learn and adapt to
                new situations quickly. Goal oriented and always working with
                the end result in mind.
              </p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>David Meneses</span>
                    <br />
                    <span>Chicago, IL</span>
                    <br />
                    <span>630.408.8590</span>
                    <br />
                    <span>dave@davemeneses.com</span>
                  </p>
                </div>
                <div className="columns download">
                  <p>
                    <a
                      href="images/meneses.pdf"
                      className="button"
                      target="_blank"
                    >
                      <i className="fa fa-download" />
                      Download Resume
                    </a>
                  </p>
                </div>
              </div>{" "}
              {/* end row */}
            </div>{" "}
            {/* end .main-col */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

import React, { Component } from "react";
export default class Resume extends Component {
  render() {
    // const divEndorse = {
    //   fontStyle: "italic"
    // };
    return (
      <React.Fragment>
        {/*generated code*/}
        <section id="resume">
          {/* Education
      ----------------------------------------------- */}
          {/* <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Education</span>
              </h1>
            </div>
            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Northwestern University</h3>
                  <p className="info">
                    Full Stack Coding Boot Camp <span>•</span>{" "}
                    <em className="date">May 2019</em>
                  </p>
                  <p>
                    12-week intensive, full-time, coding boot camp at
                    Northwestern University. Learned how to develop and deploy
                    websites from Front to Back with a focus on the MERN stack.
                  </p>
                </div>
              </div>{" "}
              <div className="row item">
                <div className="twelve columns">
                  <h3>Trinity International University</h3>
                  <p className="info">
                    B.A. in History/Social Science with Secondary Education
                    Licensure <span>•</span> <em className="date">May 2014</em>
                  </p>
                  <p>
                    <strong>Additional Educational Endorsements</strong>:
                    Psychology & K-8 Music.
                    <br />
                    <strong>Groups & Activities:</strong> Band, Orchestra,
                    Special Education Peer Mentor, Student Government. <br />
                    <strong>Accolades & Experiences:</strong> Music Scholarship
                    for the saxophone. Toured the US multiple times with the
                    music department as well as a month long summer tour in the
                    Ukraine in 2010.
                    <br />
                  </p>
                </div>
              </div>{" "}
              <div className="row item">
                <div className="twelve columns">
                  <h3>Universidad Internacional Menéndez Pelayo</h3>
                  <p className="info">
                    Study Abroad Program in Sevilla, Andalusia, Spain{" "}
                    <span>•</span> <em className="date">Spring 2012</em>
                  </p>
                  <p>
                    Competitive study abroad program with a focus on European
                    Union politics and economics as well as Spanish language and
                    culture.{" "}
                  </p>
                </div>
              </div>{" "}
            </div>{" "}
          </div>{" "} */}
          {/* End Education */}
          {/* Work
      ----------------------------------------------- */}
          {/* <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Work</span>
              </h1>
            </div>
            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  <h3>Northwestern University</h3>
                  <p className="info">
                    Teaching Assistant & Tutor <span>•</span>{" "}
                    <em className="date">May 2019 - Present</em>
                  </p>
                  <p>
                    Instructional team member for the full time Coding Boot
                    Camp. As a TA and one-on-one tutor I am consistently
                    debugging students’ code as well as encouraging and guiding
                    students towards solutions. My main responsibilities include
                    assisting students in the classroom with activities, grading
                    homework, and meeting with students individually to work
                    through assignments and projects.
                  </p>
                </div>
              </div>{" "}
              <div className="row item">
                <div className="twelve columns">
                  <h3>Stumptown Coffee Roasters</h3>
                  <p className="info">
                    Manager & Senior Lead <span>•</span>{" "}
                    <em className="date">Oct 2017 - Feb 2019</em>
                  </p>
                  <p>
                    Opened the flagship Stumptown Coffee in Chicago. Responsible
                    for all hiring, training, and management of the team.
                    Managed vendor relationships for the brand. Trained other
                    local accounts as well as coordinated all Stumptown events
                    in the Chicagoland area.
                  </p>
                </div>
              </div>{" "}
              <div className="row item">
                <div className="twelve columns">
                  <h3>Intelligentsia Coffee</h3>
                  <p className="info">
                    Shift Lead & Roasting Works Tour Guide <span>•</span>{" "}
                    <em className="date">Nov 2015 - Oct 2017</em>
                  </p>
                  <p>
                    Retail leader in highest volume store in the Intelligentsia
                    brand. Responsible for QA as well as leading the retail team
                    in day to day business. Conducted guided tours of the
                    Chicago Roasting Works space.
                  </p>
                </div>
              </div>{" "}
              <div className="row item">
                <div className="twelve columns">
                  <h3>Trinity International University</h3>
                  <p className="info">
                    Trinity Fund Coordinator <span>•</span>{" "}
                    <em className="date">Aug 2012 - Nov 2015</em>
                  </p>
                  <p>
                    University advancement officer. Led a team of 30 employees
                    in raising money for the Trinity Fund, a fund that provides
                    over $10 million in scholarships and aid annually. Managed
                    online giving database. Processed all phone and online gifts
                    to the university.
                  </p>
                </div>
              </div>{" "}
            </div>{" "}
          </div>{" "} */}
          {/* End Work */}
          {/* Skills
      ----------------------------------------------- */}
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Skill-Set</span>
              </h1>
            </div>
            <div className="nine columns main-col">
              <p>
                {/* Full development skill set with a few of my strongest skills
                highlighted:
                <br /> */}
                Creative Problem Solving - Object-Oriented Programming - HTML5 -
                CSS - Javascript - ES6 - jQuery - Sass - Bootstrap - Materialize
                - MERN Stack (MongoDB / Mongoose, Express, React, Node) - REST
                APIs - MVC - Handlebars - Firebase - SQL - MySQL - Sequelize -
                Heroku - Git - Github - Gitlab - Agile Methodology - Kanban -
                Trello
              </p>
              {/* <div className="bars">
                <ul className="skills">
                  <li>
                    <span className="bar-expand javaScript" />
                    <em>JavaScript</em>
                  </li>
                  <li>
                    <span className="bar-expand react" />
                    <em>React</em>
                  </li>
                  <li>
                    <span className="bar-expand css" />
                    <em>CSS</em>
                  </li>
                  <li>
                    <span className="bar-expand html" />
                    <em>HTML</em>
                  </li>
                  <li>
                    <span className="bar-expand mongoDB" />
                    <em>MongoDB</em>
                  </li>
                  <li>
                    <span className="bar-expand sql" />
                    <em>SQL</em>
                  </li>
                </ul>
              </div> */}
              {/* end skill-bars */}
            </div>{" "}
            {/* main-col end */}
          </div>{" "}
          {/* End skills */}
        </section>
      </React.Fragment>
    );
  }
}

import React, { Component } from "react";
import Header from "./components/header/header";
import About from "./components/about/about";
import Resume from "./components/resume/resume";
// import Portfolio from "./components/portfolio/portfolio";
import Secret from "./components/secret/secret";
// import Testimonials from "./components/testimonials/testimonials";
// import ContactUs from "./components/contactus/contactus";
import Footer from "./components/footer/footer";
import resumeData from "./resumeData";
class App extends Component {
  render() {
    return (
      <div className="App">
        <Header resumeData={resumeData} />
        <About resumeData={resumeData} />
        <Resume resumeData={resumeData} />
        {/* <Testimonials resumeData={resumeData} /> */}
        {/* <ContactUs resumeData={resumeData} /> */}
        {/* <Portfolio resumeData={resumeData} /> */}
        <Footer resumeData={resumeData} />
        <Secret></Secret>
      </div>
    );
  }
}
export default App;
